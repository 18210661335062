<template>
  <v-container class="pa-0">
    <base-material-card
      color="accent"
      icon="mdi-email-multiple"
      inline
      class="px-5 py-3 mt-7"
      style="width: 100%"
    >
      <template
        v-slot:after-heading
      >
        <v-row align="center">
          <v-col
            v-if="showViewAsCustomer"
            class=""
            cols="6"
            sm="6"
            md="4"
            lg="4"
          >
            <ViewAsCustomer />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="6"
            md="3"
            lg="2"
          >
            <FilterUserNowwId />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="4"
            md="3"
            lg="2"
          >
            <StatusFilter
              :items="campaignStatusItems"
              prop-name="campaignStatus"
              store-action="setCampaignStatus"
              label="Campaign Status"
            />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="4"
            md="3"
            lg="2"
          >
            <DaysFromNow
              label="Modified"
              :disabled="viewMode === 'calendar'"
            />
          </v-col>
          <v-col
            class=""
            cols="6"
            sm="3"
            md="2"
          >
            <v-tooltip
              v-if="disableNewCampaign"
              top
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  readonly
                  dark
                  color="grey"
                  :loading="isLoading()"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-email-plus
                  </v-icon>
                  New Campaign
                </v-btn>
              </template>
              <span>Please select Dashboard filters for a new Campaign</span>
            </v-tooltip>
            <v-menu
              v-else
              v-model="newCampaignMenu"
              right
              x-offset
              origin="right"
              transition="slide-x-transition"
              :close-on-click="false"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on: onMenu }">
                <v-btn
                  color="success"
                  v-on="{ ...onMenu }"
                >
                  <v-icon left>
                    mdi-email-plus
                  </v-icon>
                  New Campaign
                </v-btn>
              </template>
              <v-card width="500">
                <v-card-text class="pa-5">
                  <p
                    v-if="!viewAsCustomerAcousticLoginId()"
                    class="red--text"
                  >
                    <span v-if="hasMMSTenantManagement">
                      Please assign an Acoustic Login for this customer.
                      <v-btn
                        color="primary"
                        class="ml-2"
                        @click="$router.push({ name: 'Account' })"
                      >
                        <v-icon
                          left
                        >
                          mdi-account-multiple
                        </v-icon>
                        Account
                      </v-btn>
                    </span>
                    <span v-else>
                      Please contact an MMS Admin to register an Acoustic Login for this account.
                    </span>
                  </p>
                  <v-text-field
                    v-else
                    ref="newCampaignName"
                    v-model="newCampaignName"
                    required
                    :rules="newCampaignRules"
                    label="Campaign Name"
                    :loading="menuLoading"
                    @keyup.enter="createCampaign()"
                  />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="tertiary"
                    :disabled="menuLoading"
                    @click="closeNewCampaignMenu()"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="success"
                    :disabled="!viewAsCustomerAcousticLoginId()"
                    :loading="menuLoading"
                    @click="createCampaign()"
                  >
                    Create
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <CampaignTable
        @changeViewMode="changeViewMode"
      />
    </base-material-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import claims from '@/shared/models/auth/claims'
import { campaignStatus } from '@/shared/models/mailing'
import { mailingService } from '@/shared/services'

export default {
  name: 'CampaignDashboard',

  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer'),
    FilterUserNowwId: () =>
      import('@/views/pages/components/user-state/FilterUserNowwId'),
    StatusFilter: () =>
      import('@/views/pages/components/user-state/StatusFilter'),
    DaysFromNow: () =>
      import('@/views/pages/components/user-state/DaysFromNow'),
    CampaignTable: () =>
      import('@/views/pages/dashboards/CampaignTable')
  },

  data: function () {
    return {
      newCampaignName: null,
      newCampaignMenu: false,
      menuLoading: false,
      newCampaignRules: [
        value => !!value || 'Campaign Name is required'
      ],
      viewMode: 'grid'
    }
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),
    ...mapGetters(['isLoading', 'viewAsCustomerAcousticLoginId']),
    showViewAsCustomer () {
      return this.hasClaimKV(claims.MMS_TEBT)
    },
    hasMMSTenantManagement () {
      return this.hasClaimKV(claims.MMS_TM)
    },
    campaignStatusItems () {
      return campaignStatus
    },
    disableNewCampaign () {
      return (
        this.isLoading() ||
        (this.$store.getters['auth/isMMSRole']() &&
          this.$store.getters['isViewAllCustomers']()) ||
        (this.$store.getters['isSimulatedAsCustomer']() &&
          this.$store.getters['filterUserNowwId']() === 0))
    }
  },

  created () {
    this.$store.commit('mailing/reset', null)
    this.viewMode = this.$store.getters.dashboardViewMode()
  },

  methods: {
    async createCampaign () {
      if (!this.$refs.newCampaignName.valid) {
        this.$refs.newCampaignName.focus()
        return
      }
      this.menuLoading = true
      await mailingService
        .createCampaign({
          customerCrmId: this.$store.getters['simulatedCustomerCrmId'](),
          contactNowwId: this.$store.getters['filterUserNowwId']() === 0
            ? this.$store.getters['auth/getUserNowwId']()
            : this.$store.getters['filterUserNowwId'](),
          campaignName: this.newCampaignName
        })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.$store.dispatch('mailing/setMailing', resp.broadcastId)
            this.$router.push({ name: 'MailingCampaign', params: { mailingItemId: resp.broadcastId } })
          }
        })
      this.menuLoading = false
    },
    closeNewCampaignMenu () {
      this.newCampaignName = null
      this.newCampaignMenu = false
    },
    changeViewMode (viewMode) {
      this.viewMode = viewMode
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
